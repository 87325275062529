export function paddingTop() {
	// headerの高さを取得してそれに30px追加した値をheaderHeightに代入
  const headerHeight = document.querySelector('.l-header_inner').clientHeight;
  const paddingTop = headerHeight + 30;

  const targetArr = document.querySelectorAll('.js-paddingTop');
  for (let index = 0; index < targetArr.length; index++) {
    const target = targetArr[index];
    target.style.paddingTop = paddingTop + 'px';
    target.style.marginTop = -paddingTop + 'px';
  }
}