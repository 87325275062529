export function waveAnime() {

  const waveElement = document.querySelector('.js-wave');

  if(waveElement) {

    var unit = 100,
        canvasList, // キャンバスの配列
        info = {}, // 全キャンバス共通の描画情報
        colorList; // 各キャンバスの色情報
    
    const init = () => {
      info.seconds = 0;
      info.t = 0;
      canvasList = [];
      colorList = [];

      // canvas1個めの色指定
      canvasList.push(document.getElementById("waveCanvas01"));
      colorList.push('gradation01');//重ねる波の色設定

      // canvas1個めの色指定
      canvasList.push(document.getElementById("waveCanvas02"));
      colorList.push('gradation02');//重ねる波の色設定

      // 各キャンバスの初期化
      for(var canvasIndex in canvasList) {
        var canvas = canvasList[canvasIndex];
        canvas.width = document.documentElement.clientWidth; //Canvasのwidthをウィンドウの幅に合わせる
        if (window.matchMedia('(max-width: 767px)').matches) {
          //スマホ処理
          canvas.height = 140; //波の高さ
        } else if (window.matchMedia('(min-width:768px)').matches) {
          //PC処理
          canvas.height = 208; //波の高さ
        }
        canvas.contextCache = canvas.getContext("2d");
      }
      // 共通の更新処理呼び出し
      update();
    }

    const update = () => {
      for(var canvasIndex in canvasList) {
        var canvas = canvasList[canvasIndex];
        // 各キャンバスの描画
        draw(canvas, colorList[canvasIndex]);
      }
      // 共通の描画情報の更新
      info.seconds = info.seconds + .014;
      info.t = info.seconds*Math.PI;
      // 自身の再起呼び出し
      setTimeout(update, 35);
    }

    const draw = (canvas, color) => {
      // 対象のcanvasのコンテキストを取得
      var context = canvas.contextCache;
      // キャンバスの描画をクリア
      context.clearRect(0, 0, canvas.width, canvas.height);
      let lineargradient = context.createLinearGradient(canvas.width, 0, canvas.width, canvas.height);
      if(color === 'gradation01') {
        lineargradient.addColorStop(0, '#fff');
        lineargradient.addColorStop(1, '#00c7b3');
        context.fillStyle = lineargradient;
      } else if(color === 'gradation02') {
        lineargradient.addColorStop(0, '#fff');
        lineargradient.addColorStop(1, '#00B3C7');
        context.fillStyle = lineargradient;
      }
      //波の重なりを描画 drawWave(canvas, color[数字（波の数を0から数えて指定）], 透過, 波の幅のzoom,波の開始位置の遅れ )
      
      // drawWave(canvas, color[2], 0.2, 1.6, 100);
      if (window.matchMedia('(max-width: 767px)').matches) {
        //スマホ処理
        drawWave(canvas, context.fillStyle, 1, 0.8, 0);
        drawWave(canvas, context.fillStyle, 0.4, 0.6, 250);
      } else if (window.matchMedia('(min-width:768px)').matches) {
        //PC処理
        drawWave(canvas, context.fillStyle, 1, 1.5, 0);
        drawWave(canvas, context.fillStyle, 0.4, 1, 250);
      }
    }

    const drawWave = (canvas, color, alpha, zoom, delay) => {
      var context = canvas.contextCache;
      context.globalAlpha = alpha;
      context.beginPath(); //パスの開始
      drawSine(canvas, info.t / 2, zoom, delay);
      context.lineTo(canvas.width + 10, canvas.height); //パスをCanvasの右下へ
      context.lineTo(0, canvas.height); //パスをCanvasの左下へ
      context.closePath() //パスを閉じる
      context.fill(); //塗りつぶす
    }

    const drawSine = (canvas, t, zoom, delay) => {
      var xAxis = Math.floor(canvas.height/4);
      var yAxis = 0;
      var context = canvas.contextCache;

      var x = t; //時間を横の位置とする
      var y = Math.sin(x)/zoom;
      context.moveTo(yAxis, unit*y+xAxis); //スタート位置にパスを置く

      // Loop to draw segments (横幅の分、波を描画)
      for (var i = yAxis; i <= canvas.width + 10; i += 10) {
        x = t+(-yAxis+i)/unit/zoom;
        y = Math.sin(x - delay)/3;
        context.lineTo(i, unit*y+xAxis);
      }
    }

    init();
  }
}