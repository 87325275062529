export function hamburger() {
  //ハンバーガーボタン
  const Trigger = document.querySelector('.js-hamburgerTrigger');
  //ヘッダー
  const header = document.querySelector('.l-header');
  Trigger.addEventListener('click', () => {
    if ( header.classList.contains('is-active') == true ) {
      header.classList.remove('is-active');
      header.classList.add('is-passive');
      setTimeout(() => {
        header.classList.remove('is-passive');
      }, "400")
    } else {
      header.classList.add('is-active');
      header.classList.remove('is-passive');
    }
  });
}
