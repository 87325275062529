import { scrollmagic } from './modules/scrollmagic';
import { hamburger } from './modules/hamburger';
// import { accordion } from './modules/accordion';
import { smooth } from './modules/smooth';
import { paddingTop } from './modules/paddingTop';
import { wavify } from './modules/wavify';
import { waveAnime } from './modules/waveAnime';
// import { waveMove } from './modules/waveMove';
// import { wave } from './modules/wave';
scrollmagic();
hamburger();
// accordion();
smooth();
paddingTop();
waveAnime();
// waveMove();
// wave();
wavify();