export function wavify() {
  const wavify = document.querySelector('.js-wavify');
  if(wavify) {
    $('#wave1').wavify({
      height: 50,
      bones: 3,
      amplitude: 25,
      color: '#00c7b3',
      speed: 0.15,
    });
    $('#wave2').wavify({
      height: 50,
      bones: 3,
      amplitude: 40,
      color: '#00c7b3',
      speed: 0.2,
    });
  
    $('#wave1Sp').wavify({
      height: 50,
      bones: 2,
      amplitude: 25,
      color: '#00c7b3',
      speed: 0.15,
    });
    $('#wave2Sp').wavify({
      height: 50,
      bones: 2,
      amplitude: 40,
      color: '#00c7b3',
      speed: 0.2,
    });
  
    $('#wave3').wavify({
      height: 50,
      bones: 3,
      amplitude: 20,
      color: '#00b3c7',
      speed: 0.15,
    });
    $('#wave4').wavify({
      height: 50,
      bones: 3,
      amplitude: 45,
      color: '#00b3c7',
      speed: 0.2,
    });
  
    $('#wave3Sp').wavify({
      height: 50,
      bones: 2,
      amplitude: 20,
      color: '#00b3c7',
      speed: 0.15,
    });
    $('#wave4Sp').wavify({
      height: 50,
      bones: 2,
      amplitude: 45,
      color: '#00b3c7',
      speed: 0.2,
    });
  }


  // $('#wave5').wavify({
  //   height: 50,
  //   bones: 3,
  //   amplitude: 25,
  //   color: '#00b4c8',
  //   speed: 0.15,
  // });
  // $('#wave6').wavify({
  //   height: 50,
  //   bones: 3,
  //   amplitude: 40,
  //   color: '#00b4c8',
  //   speed: 0.2,
  // });

  // $('#wave7').wavify({
  //   height: 50,
  //   bones: 3,
  //   amplitude: 20,
  //   color: '#00b4c8',
  //   speed: 0.15,
  // });
  // $('#wave8').wavify({
  //   height: 50,
  //   bones: 3,
  //   amplitude: 45,
  //   color: '#00b4c8',
  //   speed: 0.2,
  // });
}